import dynamic from "next/dynamic";

const RTCWebSocketProvider = dynamic ( () => import ( "_components/features/rtcSocket" ) );

const SocketWrapper = ( { children, withSocket } ) => {
	if ( withSocket ) {
		return (
			<RTCWebSocketProvider>
				{children}
			</RTCWebSocketProvider>
		);
	}

	return children;
};

export default SocketWrapper;
